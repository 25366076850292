import { createApp } from 'vue';
import VueProgressBar from "@aacassandra/vue3-progressbar";
import VueYandexMetrika from 'vue-yandex-metrika' 

import App from './App.vue'
import router from './router'
import { 
  SUPPORT_LOCALES, DEFAULT_LOCALE,
  loadLocaleMessages, setI18nLanguage, 
  setupI18n,
} from './i18n';
import store from './store'
import axios from 'axios'
import 'vuetify/styles';
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { createVuetify } from 'vuetify';

const options = {
  color: "#7C57FC",
  failedColor: "#000",
  thickness: "3px", 
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300
  },
  autoRevert: true,
  location: "top",
  inverse: false
}

axios.defaults.baseURL = process.env.VUE_APP_SITE_URL || 'https://barsel.media';
// axios.defaults.baseURL = 'https://barselmedia.ru'

const vuetify = createVuetify({
  directives,
  components,
});

const locale = localStorage.getItem('locale') || DEFAULT_LOCALE;

const i18n = setupI18n({
  locale,
  fallbackLocale: SUPPORT_LOCALES[1]
});

await loadLocaleMessages(i18n, locale);

const app = createApp(App);

app.use(i18n);

if (process.env.NODE_ENV === "production") {
    app.use(VueYandexMetrika, {
        id: Number(process.env.VUE_APP_YANDEX_METRIKA_ID) || 93810436,
        router: router,
        env: process.env.NODE_ENV
    });
}

router.beforeEach(async (to, from, next) => {
  const paramsLocale = to.params.locale || locale;

  if (!SUPPORT_LOCALES.includes(paramsLocale)) {
    return next(`/${locale}`);
  }

  if (!i18n.global.availableLocales.includes(paramsLocale)) {
    await loadLocaleMessages(i18n, paramsLocale);
  }

  setI18nLanguage(i18n, paramsLocale);

  return next();
});

app
.use(VueProgressBar, options)
.use(vuetify)
.use(store)
.use(router, axios)
.mount('#app');
