<script setup>
import { onErrorCaptured, ref, defineEmits, defineProps, watch, defineModel, onMounted, onBeforeUnmount, toRefs, computed } from 'vue';
import { useStore } from "vuex";
const formatTime = (second) => new Date(Number(second || 0) * 1000).toISOString().substring(11, 8);

onErrorCaptured((_, instance) => {
    console.log(instance.$options.name);
});

const goToUp = () => {
    const app = document.getElementById('app');

    app && app.scrollIntoView({ behavior: 'smooth' });
};

const store = useStore();

const emit = defineEmits(['clear']);

const props = defineProps({
    inEfir: {
        type: Boolean,
        default: false
    },
    cur_time: {
        type: Number,
        default: 0
    },
    radio: {
        type: [Object, String],
        default: {},
    },
    file: {
        type: String,
        default: null,
    },
    autoPlay: {
        type: Boolean,
        default: false
    },
    newRadioRedirect: {
        type: Boolean,
        default: false,
    },
    ended: {
        type: Function,
        default: () => {},
    },
    canPlay: {
        type: Function,
        default: () => {},
    },
    downloadable: {
        type: Boolean,
        default: false,
    }
});

const audio = ref(null);
const playBtn = ref(null);
const firstPlay = ref(true);
const playing = ref(false);
const paused = ref(false);
const loading = ref(true);
const loaded = ref(false);
const isMuted = ref(false);
const currentTime = ref('00:00:00');
const totalDuration = ref(0);
const playerVolume = defineModel('playerVolume', { default: 0.5 });
const percentage = defineModel('percentage', { default: 0 });
const posWidth = ref(50);
const posPercentage = ref(0);

const { inEfir, file, newRadioRedirect, cur_time, autoPlay, radio } = toRefs(props);

const duration = computed(() => {
    return audio.value ? formatTime(totalDuration.value) : '';
});

const setRadioTimeCur = (value) => {
    store.commit('setRtime', Number(value));
    localStorage.setItem('rtime', Number(value));
};

const setPosition = () => {
    if (!audio.value) return;

    paused.value ? audio.value.pause() : audio.value.play();

    audio.value.currentTime = parseInt(audio.value.duration / 100 * percentage.value);
};

const stop = () => {
    if (!audio.value) return;

    audio.value.pause();
    paused.value = true;
    playing.value = false;
    audio.value.currentTime = 0;
}

const play = () => {
    if (playing.value) return;
    if (!audio.value) return;
    audio.value.play().then(() => playing.value = true);
    paused.value = false;
};

const pause = () => {
    paused.value = !paused.value;
    if (!audio.value) return;
    paused.value ? audio.value.pause() : audio.value.play();
};

const download = () => {
    audio.value && audio.value.pause();
    window && window.open(file.value, 'download');
};

const mute = () => {
    isMuted.value = !isMuted.value;
    if (!audio.value) return;
    audio.value.muted = isMuted.value;
};

const reload = () => {
    audio.value && audio.value.load();
};

const close = () => {
    localStorage.removeItem('radio');
    store.commit('removeRadio');
    store.commit('initializeStore');

    inEfir.value && emit('clear', {});
};

const handleDuration = () => {
    if (!audio.value) return;
    
    totalDuration.value = parseInt(Number(audio.value.duration) || 0);
    loaded.value = true;
}

const handleLoaded = () => {
    if (!audio.value) return;

    if (audio.value.readyState < 2) throw new Error("Failed to load sound file");

    if (audio.value.duration === Infinity) {
        audio.value.currentTime = 1e101;
        audio.value.ontimeupdate = () => {
            audio.value.ontimeupdate = () => {};
            audio.value.currentTime = 0;
            handleDuration();
        };
    } else {
        handleDuration();
    }

    if(autoPlay.value) {
        audio.value.play();
        playing.value = true;
    }
};

const handlePlayingUI = () => {
    if (!audio.value) return;

    audio.value.volume = playerVolume.value;
    percentage.value = audio.value.currentTime / (audio.value.duration || 1) * 100;
    currentTime.value = formatTime(audio.value.currentTime);
    playing.value = true;
};

const handlePlayPause = (e) => {
    if (e.type === 'play' && firstPlay.value && audio.value) {
        audio.value.currentTime = newRadioRedirect.value ? cur_time.value : 0;

        if (firstPlay.value) firstPlay.value = false;
    }

    if (e.type === 'pause' && !paused.value && !playing.value) {
        currentTime.value = '00:00:00';
    }
}

const handleEnded = () => {
    paused.value = playing.value = false;
};

const init = () => {
    if (!audio.value) return;
    
    audio.value.addEventListener('timeupdate', handlePlayingUI);
    audio.value.addEventListener('loadeddata', handleLoaded);
    audio.value.addEventListener('pause', handlePlayPause);
    audio.value.addEventListener('play', handlePlayPause);
    audio.value.addEventListener('ended', handleEnded);
};

onMounted(() => {
    init();
    
    if (!inEfir.value) {
        loading.value = false;
    }

    setTimeout(() => {
        loading.value = false;
    }, 500);

    setTimeout(() => {
        if (!inEfir.value) return;

        if (!playing.value) playBtn.value.click();
    }, 2000);
});

onBeforeUnmount(() => {
    if (!audio.value) return;
    store.commit('setRadio', null);
    localStorage.setItem('radio', null);

    store.commit('initializeStore');

    audio.value.removeEventListener('timeupdate', handlePlayingUI);
    audio.value.removeEventListener('loadeddata', handleLoaded);
    audio.value.removeEventListener('pause', handlePlayPause);
    audio.value.removeEventListener('play', handlePlayPause);
    audio.value.removeEventListener('ended', handleEnded);
});

watch(playerVolume, (value) => {
    posWidth.value = parseInt((Number(value) || 0) * 100);
});

watch(percentage, (value) => {
    posPercentage.value = parseInt(Number(value) || 0);
});
</script>


<template>
    <div class="music-modal" style="text-align: center" v-if="file">
        <div class="pos_abs-ring" v-if="loading">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div 
            class="music-modal__play" 
            ref="playBtn"
            @click.capture="playing ? pause() : play()"
            :class="{ 'loadMusic': loading }"
            :aria-disabled="!loaded"
        >
            <span v-if="playing && !paused">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3.21799 4.09202C3 4.51984 3 5.0799 3 6.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.0799 21 6.2 21H6.8C7.9201 21 8.48016 21 8.90798 20.782C9.28431 20.5903 9.59027 20.2843 9.78201 19.908C10 19.4802 10 18.9201 10 17.8V6.2C10 5.0799 10 4.51984 9.78201 4.09202C9.59027 3.71569 9.28431 3.40973 8.90798 3.21799C8.48016 3 7.9201 3 6.8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202Z"
                        fill="white" 
                    />
                    <path
                        d="M14.218 4.09202C14 4.51984 14 5.0799 14 6.2V17.8C14 18.9201 14 19.4802 14.218 19.908C14.4097 20.2843 14.7157 20.5903 15.092 20.782C15.5198 21 16.0799 21 17.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V6.2C21 5.0799 21 4.51984 20.782 4.09202C20.5903 3.71569 20.2843 3.40973 19.908 3.21799C19.4802 3 18.9201 3 17.8 3H17.2C16.0799 3 15.5198 3 15.092 3.21799C14.7157 3.40973 14.4097 3.71569 14.218 4.09202Z"
                        fill="white" 
                    />
                </svg>
            </span>
            <span v-else>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.36077 1.27343C3.36667 0.57687 2 1.28802 2 2.50188V9.49827C2 10.7121 3.36668 11.4233 4.36078 10.7267L9.36486 7.22028C10.2115 6.62704 10.2115 5.373 9.36484 4.77977L4.36077 1.27343Z"
                        fill="#fff"
                    />
                </svg>
            </span>
        </div>
        <div class="music-modal__sound" :class="{ 'widthActive': inEfir, 'loadMusic': loading }">
            <input
                v-model="percentage"
                type="range" 
                @click.capture="setPosition" 
                :style="{ 'background-size': `${posPercentage}% 100%` }"
                :disabled="!loaded" 
                v-if="!inEfir" 
            />
        </div>
        <audio id="player" ref="audio" @ended="ended" @canplay="canPlay" :src="file"></audio>
        <div class="music-modal__prev" :class="{ 'loadMusic': loading }">
            <img src="@/assets/img/Cover_radio.jpg" alt="" v-if="radio.get_preview === ''" />
            <img :src="radio.get_preview" :alt="radio.name" v-else />
        </div>
        <div class="music-modal__info" :class="{ 'loadMusic': loading }">
            <div class="music-modal__track">{{ radio.name }}</div>
            <div class="music-modal__name" v-if="radio.author">{{ radio.author.name }}</div> 
        </div>
        <div class="music-modal__volume" :class="{ 'loadMusic': loading }" :disabled="!loaded">
            <span @click.capture="mute()" v-if="isMuted">
                <span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.0997 5.40514C13.0997 3.52196 10.8877 2.50928 9.46218 3.73986L4.99078 7.59989H3.19995C1.98495 7.59989 1 8.58484 1 9.79984V14.1997C1 15.4147 1.98495 16.3997 3.19995 16.3997H4.99076L9.46211 20.26C10.8876 21.4907 13.0997 20.478 13.0997 18.5948V5.40514Z"
                                fill="#019040" />
                    </svg>
                </span>
            </span>
            <span @click.capture="mute()" v-else>
                <span v-if="playerVolume >= 0.51">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.7148 3.56503C16.1326 3.39174 15.5201 3.72328 15.3468 4.30554C15.1735 4.8878 15.505 5.50029 16.0873 5.67358C18.8142 6.48516 20.8009 9.01214 20.8009 12.0003C20.8009 14.9884 18.8142 17.5153 16.0873 18.3269C15.505 18.5002 15.1735 19.1127 15.3468 19.695C15.5201 20.2772 16.1326 20.6088 16.7148 20.4355C20.349 19.3539 23.0009 15.9882 23.0009 12.0003C23.0009 8.0123 20.349 4.64661 16.7148 3.56503Z"
                            fill="#019040" 
                        />
                        <path
                            d="M13.0997 5.40514C13.0997 3.52196 10.8877 2.50928 9.46218 3.73986L4.99078 7.59989H3.19995C1.98495 7.59989 1 8.58484 1 9.79984V14.1997C1 15.4147 1.98495 16.3997 3.19995 16.3997H4.99076L9.46211 20.26C10.8876 21.4907 13.0997 20.478 13.0997 18.5948V5.40514Z"
                            fill="#019040" 
                        />
                        <path
                            d="M17.1786 9.02237C16.749 8.5928 16.0525 8.5928 15.623 9.02237C15.1934 9.45194 15.1934 10.1484 15.623 10.578C16.1614 11.1165 16.4008 11.3838 16.4008 11.9999C16.4008 12.6161 16.1612 12.884 15.623 13.4223C15.1934 13.8518 15.1934 14.5483 15.623 14.9779C16.0525 15.4074 16.749 15.4074 17.1786 14.9779L17.2068 14.9497C17.7686 14.3883 18.6007 13.5568 18.6007 11.9999C18.6007 10.4433 17.769 9.61233 17.2074 9.05119L17.1786 9.02237Z"
                            fill="#019040" 
                        />
                    </svg>
                </span>
                <span v-else-if="playerVolume > 0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.0997 5.40514C13.0997 3.52196 10.8877 2.50928 9.46218 3.73986L4.99078 7.59989H3.19995C1.98495 7.59989 1 8.58484 1 9.79984V14.1997C1 15.4147 1.98495 16.3997 3.19995 16.3997H4.99076L9.46211 20.26C10.8876 21.4907 13.0997 20.478 13.0997 18.5948V5.40514Z"
                            fill="#019040" 
                        />
                        <path
                            d="M17.1786 9.02237C16.749 8.5928 16.0525 8.5928 15.623 9.02237C15.1934 9.45194 15.1934 10.1484 15.623 10.578C16.1614 11.1165 16.4008 11.3838 16.4008 11.9999C16.4008 12.6161 16.1612 12.884 15.623 13.4223C15.1934 13.8518 15.1934 14.5483 15.623 14.9779C16.0525 15.4074 16.749 15.4074 17.1786 14.9779L17.2068 14.9497C17.7686 14.3883 18.6007 13.5568 18.6007 11.9999C18.6007 10.4433 17.769 9.61233 17.2074 9.05119L17.1786 9.02237Z"
                            fill="#019040" 
                        />
                    </svg>
                </span>
                <span v-else>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.0997 5.40514C13.0997 3.52196 10.8877 2.50928 9.46218 3.73986L4.99078 7.59989H3.19995C1.98495 7.59989 1 8.58484 1 9.79984V14.1997C1 15.4147 1.98495 16.3997 3.19995 16.3997H4.99076L9.46211 20.26C10.8876 21.4907 13.0997 20.478 13.0997 18.5948V5.40514Z"
                            fill="#019040" 
                        />
                    </svg>
                </span>
            </span>
            <div>
                <input 
                    type="range"
                    v-model="playerVolume"
                    step="0.01"
                    max="1"
                    min="0"
                    :style="{ 'background-size': `${posWidth}% 100%` }"
                />
            </div>
        </div>
        <div class="music-modal__arrow" @click="goToUp" :class="{ 'loadMusic': loading }">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="var(--nav-green)" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
        <div class="music-modal__close" @click="close" :class="{ 'loadMusic': loading }">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 6L6 18" stroke="#ABABAB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6 6L18 18" stroke="#ABABAB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
        <div class="music-modal__sound__mobile" v-if="!inEfir">
            <input 
                type="range"
                v-model="percentage"
                @click.capture="setPosition()"
                :style="{ 'background-size': `${posPercentage}% 100%` }"
                :disabled="!loaded"
            />
        </div>
    </div>
    <div v-else></div>
</template>

<style>
.music-modal__volume div input {
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   background: rgba(1, 144, 63, 0.2);
   background-image: linear-gradient(var(--nav-green), var(--nav-green));
   background-repeat: no-repeat;
   background-size: 0% 100%;
   /*    background: var(--nav-green);    */
}

.music-modal__sound__mobile {
   display: none;
}

.widthActive {
   width: auto !important;
}

.loadMusic {
   opacity: 0;
}

@media (max-width: 576px) {
   .music-modal__sound__mobile {
      display: block;
   }

   .music-modal__left {
      flex-wrap: wrap;
      gap: 1rem;
   }

   .music-modal {
      flex-wrap: wrap;
   }

   .music-modal__arrow {
      position: absolute;
      bottom: 1rem;
      right: 0.625rem;
   }

   .modal-track {
      padding: 0 !important;
   }

   .music-modal__tile {
      gap: 1rem !important;
   }

   .music-modal__close {
      position: absolute;
      top: 0.375rem;
      right: 0.4375rem;
   }

   .music-modal {
      padding-bottom: 30px;
   }

   .music-modal__sound__mobile {
      margin: 0 32px;
      max-width: 500px;
      width: 100%;
      border-radius: 3px;
      position: relative;
      height: 4px;
      cursor: pointer;
   }

   .music-modal__sound__mobile input {
      position: relative;
      height: 100%;
      width: 100%;
      background: rgba(1, 144, 63, 0.2);
      background-image: linear-gradient(var(--nav-green), var(--nav-green));
      background-repeat: no-repeat;
      background-size: 0% 100%;
   }
}

.music-modal__sound input {
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   background: rgba(1, 144, 63, 0.2);
   background-image: linear-gradient(var(--nav-green), var(--nav-green));
   background-repeat: no-repeat;
   background-size: 0% 100%;
}

.music-modal__close {
   display: flex;
   justify-content: center;
   align-items: center;
   padding-right: 15px;
   padding-left: 0;
   height: 60px;
   border-radius: 100%;
   cursor: pointer;
}

/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
   -webkit-appearance: none;
   appearance: none;
   cursor: pointer;
}

/* Removes default focus */
input[type="range"]:focus {
   outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
   /*   background: var(--nav-green);*/

}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none;
   /* Override default look */
   appearance: none;
   background: var(--nav-green);

   /*    background: rgba(1, 144, 63, 0.2);*/
   text-align: left;
   border-radius: 100%;
   width: 10px;
   height: 10px;
}

/*input[type="range"]:focus::-webkit-slider-thumb {   
  border: 1px solid #053a5f;
  outline: 3px solid #053a5f;
  outline-offset: 0.125rem; 
}*/

/******** Firefox styles ********/
/* slider track */
/*input[type="range"]::-moz-range-track {
   background-color: #053a5f;
   border-radius: 0.5rem;
   height: 0.5rem;
}*/

/* slider thumb */
/*input[type="range"]::-moz-range-thumb {
   border: none; 
   border-radius: 0;
   background-color: #5cd5eb;
}
*/
/*input[type="range"]:focus::-moz-range-thumb {
  border: 1px solid #053a5f;
  outline: 3px solid #053a5f;
  outline-offset: 0.125rem; 
}*/
.pos_abs-ring {
   position: absolute;
   left: 50%;
   transform: translate(-50%, -50%);
   top: 50%;
}

.lds-ring {
   display: inline-block;
   position: relative;
   width: 80px;
   height: 80px;
}

.lds-ring div {
   box-sizing: border-box;
   display: block;
   position: absolute;
   width: 64px;
   height: 64px;
   margin: 8px;
   border: 6px solid #019040;
   border-radius: 50%;
   animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
   border-color: #019040 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
   animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
   animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
   animation-delay: -0.15s;
}

@keyframes lds-ring {
   0% {
      transform: rotate(0deg);
   }

   100% {
      transform: rotate(360deg);
   }
}
</style>

<style lang="scss" scoped>
.music-modal__prev {
    margin: 0 24px 0 0;
}

.music-modal__arrow {
    margin: 0 24px 0 0;
}

@media (max-width: 768px) {
    .music-modal {
        height: 100px;
    }

    .music-modal__prev {
        margin: 0 8px 0 8px;
    }

    .music-modal__sound__mobile {
        max-width: 250px;
    }

    .music-modal__arrow {
        margin: 0 8px 0 8px;
        bottom: 8px;
        right: 0;
    }

    .music-modal__close {
        top: 0;
        right: 0;
    }
}
</style>