<template>
    <Header />
    <section class="about">
        <div class="container">
            <i18n-t tag="h1" keypath="title.singular" scope="global"></i18n-t>
            <p class="about__subtitle">
                <i18n-t keypath="subtitle.singular" scope="global"></i18n-t>
            </p>
            <div class="about__wrapper">
                <div class="about__sidebar">
                    <ul>
                        <li :class="{ 'active': currentVal === index }" v-for="item, index in items" :key="`track${index}`">
                            <a @click="GoToRef(index)">{{ about[`title${index + 1}`] }}</a>
                        </li>
                    </ul>
                </div>
                <div class="about__block">
                    <div :class="{ [item]: index !== 0 && index < items.length - 1 }" v-for="item, index in items" ref="itemsRef" :key="`section${index}`">
                        <h2>{{ about[`title${index + 1}`] }}</h2>
                        <p class="about__text" v-if="index < items.length - 1">{{ about[`disc${index + 1}`] }}</p>
                        <div class="about__contact" v-else>
                            <div class="about__item">
                                <p class="about__bold">
                                    <i18n-t keypath="address.singular" scope="global"></i18n-t>
                                </p>
                                <div class="about__grey">
                                    <a href="mailto:barselmedia@mail.ru">barselmedia@mail.ru</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="donate"></section>
</template>

<script setup>
import axios from 'axios';
import { ref, onBeforeMount, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import Header from '@/components/Header/Header';
import { tryCatch } from '@/utils/handle';

const router = useRouter();
const about = ref({});
const currentVal = ref(-1);
const items = ref([
    'idea',
    'mis',
    'them',
    'coop',
    'contact',
]);
const itemsRef = ref([]);

const GoToRef = (value) => {
    currentVal.value = value;
    itemsRef.value[value % itemsRef.value.length].scrollIntoView({ behavior: 'smooth' });
};

const goTo = (redirect) => {
    router.push(redirect);
};

const GetAbout = async () => {
    await tryCatch(null, async () => {
        const response = await axios.get('/api/v1/get/about/');

        about.value = response.data[0];
    });
};

const observeSections = () => {
    const observer = new IntersectionObserver((entries) => {
        for (const entry of entries) {
            if (!entry.isIntersecting) continue;
            
            const index = itemsRef.value.indexOf(entry.target);

            if (index === -1) continue;

            currentVal.value = index % itemsRef.value.length;

            break;
        }
    }, {
        rootMargin: "100px",
        threshold: 1,
    });

    for (const item of itemsRef.value) {
        observer.observe(item);
    }

    onUnmounted(() => {
        for (const item of itemsRef.value) {
            observer.unobserve(item);
        }
    });
};

onBeforeMount(async () => {
    await GetAbout();
});

onMounted(() => {
    observeSections();
});
</script>
