<template>
   <Header />
   <section class="search">
      <div class="container">
         <h1>{{ $t('search.results.plural', 2) }}</h1>
         <div class="category__row_wrapper">
            <div class="category__row">
               <a @click="GoScroll('video')">
                  <i18n-t keypath="search.category.video.singular" scope="global"></i18n-t>
               </a>
               <a @click="GoScroll('radio')">
                  <i18n-t keypath="search.category.radio.singular" scope="global"></i18n-t>
               </a>
            </div>
         </div>
         <div class="search__block">
            <div ref="video">
               <div class="search__title">
                  <h2>
                     <i18n-t keypath="search.category.video.singular" scope="global"></i18n-t>
                  </h2>
                  <router-link to="/catalog" class="search__more">
                     <i18n-t scope="global" keypath="search.category.video.plural" tag="p"></i18n-t>
                     <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="#262F44" stroke-width="2"
                           stroke-linecap="round" stroke-linejoin="round" />
                     </svg>
                  </router-link>
               </div>
               <div class="category__tv">
                  <router-link v-for="item in videos" :to="'/video/item/' + item.id" class="category__tv_item">
                     <img :src="item.get_preview" v-if="item.get_preview">
                     <img src="@/assets/img/Cover_video2.jpg" v-else>
                     <div class="list-video__name">
                        <p>{{ item.name }}</p>
                     </div>
                     <div class="list-film__watch">
                        <p>{{ item.prosmotri }}</p>
                     </div>
                     <div class="list-film__date">
                        <p>{{ CorDate(item.date_added) }}</p>
                     </div>
                  </router-link>
               </div>
            </div>
            <div ref="radio">
               <div class="search__title">
                  <h2>
                     <i18n-t keypath="search.category.radio.singular" scope="global"></i18n-t>
                  </h2>
                  <router-link to="/radio/catalog" class="search__more">
                     <p>{{ $t('search.category.radio.plural', 2) }}</p>
                     <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="#262F44" stroke-width="2"
                           stroke-linecap="round" stroke-linejoin="round" />
                     </svg>
                  </router-link>
               </div>
               <div class="category__radio over__hide">
                  <a @click="SetRadio(item)" v-for="item in radios" class="music-slider__item margin-zero">
                     <div class="music-slider__prev">
                        <img :src="item.get_preview" v-if="item.get_preview">
                        <img src="@/assets/img/music_slider.jpg" v-else>
                        <div class="list-film__watch">
                           <p>{{ item.prosmotri }}</p>
                        </div>
                        <div class="list-film__date">
                           <p>{{ CorDate(item.date_added) }}</p>
                        </div>
                     </div>
                     <div class="music-slider__item_info">
                        <p class="music-slider__track">{{ item.name }}</p>
                        <p class="music-slider__name" v-if="item.author">{{ item.author.name }}</p>
                     </div>
                  </a>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<style type="text/css"></style>

<script setup>
import axios from 'axios';
import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/utils/datetime';
import { tryCatch } from '@/utils/handle';
import Header from '@/components/Header/Header';

const router = useRouter();
const store = useStore();
const { locale } = useI18n({
   useScope: 'global',
   inheritLocale: true,
});
const video = ref(null);
const radio = ref(null);
const radios = ref([]);
const videos = ref([]);
const querySearch = ref("");

const goTo = (redirect) => {
   router.push(redirect);
};

const GoScroll = (value) => {
   if (value == 'video') video.value.scrollIntoView({ behavior: 'smooth' });
   if (value == 'radio') radio.value.scrollIntoView({ behavior: 'smooth' });
};

const GetSearchVideos = async () => {
   querySearch.value = router.currentRoute.value.query.q;
   await tryCatch(null, async () => {
      const response = await axios.get(`/api/v1/search/videos/${querySearch.value}/`);
      videos.value = response.data;
   });
};

const GetSearchRadios = async () => {
   querySearch.value = router.currentRoute.value.query.q;
   await tryCatch(null, async () => {
      const response = await axios.get(`/api/v1/search/radios/${querySearch.value}/`);
      radios.value = response.data;
   });
};

const HistoryAddRadio = async (idc) => {
   await tryCatch(null, async () => {
      await axios.patch(`/api/v1/add/radio/history/${idc}/`);
      console.log('history add');
   });
};

const SetRadio = async (value) => {
   const r = JSON.stringify(value);

   store.commit('setRadio', r);
   localStorage.setItem('radio', r);

   await HistoryAddRadio(value.id);
   store.commit('initializeStore');
};

const CorDate = (date) => {
   return formatDate(date, 'DD MMMM YYYY', locale.value);
};

onMounted(async () => {
   await GetSearchVideos();
   await GetSearchRadios();
});

watch(() => router.currentRoute.value.query, async () => {
   await GetSearchVideos();
   await GetSearchRadios();
});

</script>