<script setup>
import { computed, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { LOCALE_NAMES, SUPPORT_LOCALES, LOCALE_FALLBACKS } from '@/i18n';
// import { loadLocaleMessagesOnHook, LOCALE_NAMES, SUPPORT_LOCALES } from '@/i18n';

const router = useRouter();

const props = defineProps({
    isMobile: {
        type: Boolean,
        required: true,
    },
});

const isMobile = toRef(props, 'isMobile');

const { t, locale, setLocaleMessage } = useI18n({
    useScope: 'global',
    inheritLocale: true,
});

const rotateLocale = (current) => {
    const index = SUPPORT_LOCALES.indexOf(current);
    const nextIndex = (index + 1) % SUPPORT_LOCALES.length;

    return SUPPORT_LOCALES[nextIndex];
}

const switchLanguage = async () => {
    const nextLocale = rotateLocale(locale.value);
    // await loadLocaleMessagesOnHook(locale, setLocaleMessage, nextLocale);
    localStorage.setItem('locale', nextLocale);

    // TODO: Find a better way to reload the data
    router.go(0);
}

const label = computed(() => { 
    const fallback = LOCALE_FALLBACKS[locale.value] || 'ru-cyrl';
    const l = LOCALE_NAMES[fallback] || 'Cyrillic';

    return isMobile.value ? l.slice(0, 3) : l;
});

const width = computed(() => isMobile.value ? '48px' : '88px');
</script>

<template>
    <form class="header__switch" @submit.prevent="switchLanguage">
        <button type="submit">{{ label }}</button>
    </form>
</template>

<style lang="scss">
.header__switch {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    & button {
        display: block;
        width: v-bind(width);
        height: 48px;
        background: var(--main-bg-burger);
        border-radius: 12px;
        padding: 10px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: clamp(1rem, 1.75vw, 1.125rem);
        line-height: 150%;
        /* identical to box height, or 30px */
        letter-spacing: 0.01em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'salt' on;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        color: #82919F;

        &:hover {
            background: #EDF0F3;
            color: #262F44;
        }
    }

    @media (max-width: 930px) {
        order: 2;
    }
}
</style>