<script setup>
import axios from "axios";
import { tryCatch } from "@/utils/handle"
import { computed, ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

const objects = ref([]);

const GoTo = (redirect) => {
    router.push(redirect);
}

const getAboutObject = async () => {
    await tryCatch(null, async () => {
        const r = await axios.get('/api/v1/get/about/main/');
        objects.value = r.data;
    });
};

const hasPage = computed(() => {
    return Array.isArray(objects.value) && objects.value.length !== 0;
});

onBeforeMount(async () => {
   await getAboutObject();
});
</script>

<style lang="scss" scoped>

</style>

<template>
    <div class="about-bars__items">
        <div class="about-bars" v-for="object in objects" v-if="hasPage">
            <div class="container">
                <div class="about-bars__prev light-theme">
                    <img :src="object.poster" alt="" />
                    <div class="about-bars__desc">
                        <p class="about-bars__name">{{ object.title1 }}</p>
                        <p class="about-bars__info">{{ object.disc1 }}</p>
                    </div>
                </div>
                <div class="about-bars__wrapper">
                    <h2>{{ object.title0 }}</h2>
                    <p class="about-bars__text">{{ object.disc0 }}</p>
                    <!--             <button class="btn__blue light-theme" @click="GoTo('/about')">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="9.25" transform="rotate(-90 10 10)" stroke="var(--white)"
                        stroke-width="1.5" />
                    <mask id="path-2-inside-1_501_6087" fill="white">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M16.9231 17.216C15.0267 15.3962 13.8462 12.8359 13.8462 10C13.8462 7.16407 15.0267 4.60386 16.9231 2.78397C18.8195 4.60386 20 7.16407 20 10C20 12.8359 18.8195 15.3962 16.9231 17.216Z" />
                    </mask>
                    <path
                        d="M16.9231 17.216L15.8845 18.2983L16.9231 19.295L17.9617 18.2983L16.9231 17.216ZM16.9231 2.78397L17.9617 1.7017L16.9231 0.705004L15.8845 1.7017L16.9231 2.78397ZM17.9617 16.1338C16.3478 14.585 15.3462 12.4108 15.3462 10H12.3462C12.3462 13.2611 13.7056 16.2073 15.8845 18.2983L17.9617 16.1338ZM15.3462 10C15.3462 7.58925 16.3478 5.41499 17.9617 3.86624L15.8845 1.7017C13.7056 3.79272 12.3462 6.73889 12.3462 10H15.3462ZM15.8845 3.86624C17.4984 5.41499 18.5 7.58925 18.5 10H21.5C21.5 6.73889 20.1407 3.79272 17.9617 1.7017L15.8845 3.86624ZM18.5 10C18.5 12.4108 17.4984 14.585 15.8845 16.1338L17.9617 18.2983C20.1407 16.2073 21.5 13.2611 21.5 10H18.5Z"
                        fill="var(--white)" mask="url(#path-2-inside-1_501_6087)" />
                    <mask id="path-4-inside-2_501_6087" fill="white">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M17.2163 3.07692C15.3964 4.97334 12.8362 6.15385 10.0002 6.15385C7.16429 6.15385 4.60407 4.97334 2.78418 3.07692C4.60407 1.18051 7.16429 0 10.0002 0C12.8362 0 15.3964 1.18051 17.2163 3.07692Z" />
                    </mask>
                    <path
                        d="M17.2163 3.07692L18.2985 4.11552L19.2952 3.07692L18.2985 2.03832L17.2163 3.07692ZM2.78418 3.07692L1.70191 2.03832L0.705218 3.07692L1.70191 4.11552L2.78418 3.07692ZM16.134 2.03832C14.5852 3.6522 12.411 4.65385 10.0002 4.65385V7.65385C13.2613 7.65385 16.2075 6.29447 18.2985 4.11552L16.134 2.03832ZM10.0002 4.65385C7.58947 4.65385 5.41521 3.6522 3.86645 2.03832L1.70191 4.11552C3.79294 6.29447 6.7391 7.65385 10.0002 7.65385V4.65385ZM3.86645 4.11552C5.41521 2.50164 7.58947 1.5 10.0002 1.5V-1.5C6.7391 -1.5 3.79294 -0.140628 1.70191 2.03832L3.86645 4.11552ZM10.0002 1.5C12.411 1.5 14.5852 2.50164 16.134 4.11552L18.2985 2.03832C16.2075 -0.140626 13.2613 -1.5 10.0002 -1.5V1.5Z"
                        fill="var(--white)" mask="url(#path-4-inside-2_501_6087)" />
                    <mask id="path-6-inside-3_501_6087" fill="white">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M3.07692 17.216C4.97334 15.3962 6.15384 12.8359 6.15384 10C6.15384 7.16407 4.97334 4.60386 3.07692 2.78397C1.18051 4.60386 0 7.16407 0 10C0 12.8359 1.18051 15.3962 3.07692 17.216Z" />
                    </mask>
                    <path
                        d="M3.07692 17.216L2.03832 18.2983L3.07692 19.295L4.11552 18.2983L3.07692 17.216ZM3.07692 2.78397L4.11552 1.7017L3.07692 0.705004L2.03832 1.7017L3.07692 2.78397ZM4.65384 10C4.65384 12.4108 3.6522 14.585 2.03832 16.1338L4.11552 18.2983C6.29447 16.2073 7.65384 13.2611 7.65384 10H4.65384ZM2.03832 3.86624C3.6522 5.41499 4.65384 7.58925 4.65384 10H7.65384C7.65384 6.73889 6.29447 3.79272 4.11552 1.7017L2.03832 3.86624ZM1.5 10C1.5 7.58925 2.50164 5.41499 4.11552 3.86624L2.03832 1.7017C-0.140628 3.79272 -1.5 6.73889 -1.5 10H1.5ZM4.11552 16.1338C2.50164 14.585 1.5 12.4108 1.5 10H-1.5C-1.5 13.2611 -0.140626 16.2073 2.03832 18.2983L4.11552 16.1338Z"
                        fill="var(--white)" mask="url(#path-6-inside-3_501_6087)" />
                    <mask id="path-8-inside-4_501_6087" fill="white">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M17.2163 16.9231C15.3964 18.8195 12.8362 20 10.0002 20C7.16429 20 4.60407 18.8195 2.78418 16.9231C4.60407 15.0267 7.16429 13.8462 10.0002 13.8462C12.8362 13.8462 15.3964 15.0267 17.2163 16.9231Z" />
                    </mask>
                    <path
                        d="M17.2163 16.9231L18.2985 17.9617L19.2952 16.9231L18.2985 15.8845L17.2163 16.9231ZM2.78418 16.9231L1.70191 15.8845L0.705218 16.9231L1.70191 17.9617L2.78418 16.9231ZM16.134 15.8845C14.5852 17.4984 12.411 18.5 10.0002 18.5V21.5C13.2613 21.5 16.2075 20.1406 18.2985 17.9617L16.134 15.8845ZM10.0002 18.5C7.58947 18.5 5.41521 17.4984 3.86645 15.8845L1.70191 17.9617C3.79294 20.1406 6.7391 21.5 10.0002 21.5V18.5ZM3.86645 17.9617C5.41521 16.3478 7.58947 15.3462 10.0002 15.3462V12.3462C6.7391 12.3462 3.79294 13.7055 1.70191 15.8845L3.86645 17.9617ZM10.0002 15.3462C12.411 15.3462 14.5852 16.3478 16.134 17.9617L18.2985 15.8845C16.2075 13.7055 13.2613 12.3462 10.0002 12.3462V15.3462Z"
                        fill="var(--white)" mask="url(#path-8-inside-4_501_6087)" />
                    <circle cx="9.99989" cy="10" r="1.53846" fill="var(--white)" />
                </svg>
                <p>Подробнее о компании</p>
                </button> -->
                </div>
            </div>
        </div>
    </div>
</template>