<script setup>
import { ref, onBeforeMount } from "vue";
import HomeSwiper from "@/components/Swiper/HomeSwiper";
import { getSlideShow } from "./show";

const slideShow = ref([]);

const fetchSlideShow = getSlideShow(slideShow);

onBeforeMount(async () => {
    await fetchSlideShow();
});
</script>

<template>
    <div v-if="slideShow.length > 0">
        <HomeSwiper :slideShow="slideShow" />
    </div>
    <div v-else></div>
</template>